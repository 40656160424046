import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Collection = ({ data }) => {
  const [id, setID] = useState(0);

  let product = data.products[id];
  return (
    <Container>
      <p className="title">{data.title}</p>
      <motion.div
        className="collection"
        initial={{ scale: 0.7, y: 200, opacity: 0 }}
        whileInView={{ scale: 1, y: 0, opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <motion.div className="product-names">
          {data.products.map((product) => {
            return (
              <p
                className={
                  product.id === id ? "toggle-btn selected" : "toggle-btn"
                }
                onClick={() => {
                  setID(product.id);
                }}
              >
                {product.name}
              </p>
            );
          })}
        </motion.div>
        <div className="product-container">
          <img src={product.img} alt="" className="img" />
          {/* <p className="price">{product.price}</p> */}
        </div>
      </motion.div>
    </Container>
  );
};

export default Collection;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90vw;
  margin: auto;
  z-index: 100;
  .title {
    font-family: "Avenir";
    font-size: 50px;
    font-weight: 900;
    text-align: center;
  }
  .collection {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
   
  }
  .product-container {
    display: flex;
    flex-direction: column;
  }
  .product-names {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 7px;
  }
  .toggle-btn {
    margin: 0;
    font-weight: 500;
    border: 1px solid black;
    padding: 5px;
    font-size: 15px;
    /* border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    /* border-top-left-radius: 4%;
    border-top-right-radius: 4%; */ 
  }
  .img {
    width: 90vw;
    margin-top: 20px;
  }
  .selected {
    background-color: #f55283;
    color: white;
    border: 2px solid black;
  }
`;
