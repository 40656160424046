import cookie1 from "./media/products/ajwan.png";
import cookie2 from "./media/products/bajraCoconut.png";
import cookie3 from "./media/products/jowarNankhatai.png";

import butterCookie1 from "./media/products/Bajra.png";
import butterCookie2 from "./media/products/dark chocolate.png";
import butterCookie3 from "./media/products/dark chocolate ragi.png";
import butterCookie4 from "./media/products/ooats milltet.png";

import ragiLaddoo from "./media/products/ragi laddoo.png";
import sattuLaddoo from "./media/products/sattu laddoo.png";

const collections = [
  {
    id: 0,
    title: "Mini Millet Cookies",
    products: [
      {
        id: 0,
        name: "Jowar Ajwain Jaggery ",
        img: cookie1,
        price: 199,
      },
      {
        id: 1,
        name: "Bajra Coconut Millet ",
        img: cookie2,
        price: 199,
      },
      {
        id: 2,
        name: "Jowar Jaggery Nankhatai",
        img: cookie3,
        price: 249,
      },
    ],
  },
  {
    id: 1,
    title: "The Butter fly Cookies",
    products: [
      {
        id: 0,
        name: "Bajra Almong and Cashew ",
        img: butterCookie1,
        price: 199,
      },
      {
        id: 1,
        name: "Bajra Dark Chocolate ",
        img: butterCookie2,
        price: 199,
      },
      {
        id: 2,
        name: "Ragi Dark Chocolate",
        img: butterCookie3,
        price: 249,
      },
      {
        id: 3,
        name: "Ooats and Milltes",
        img: butterCookie4,
        price: 249,
      },
    ],
  },
  {
    id: 2,
    title: "Bheem Laddoo",
    products: [
      {
        id: 0,
        name: "Ragi Jaggery Ghee Laddoos ",
        img: ragiLaddoo,
        price: 199,
      },
      {
        id: 1,
        name: "Sattu Jaggery Ghee Laddoos ",
        img: sattuLaddoo,
        price: 199,
      },
    ],
  },
];

const Data = { collections };

export default Data;
