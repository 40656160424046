import React from "react";
import styled from "styled-components";
import Data from "../../_data";
import Collection from "./components/collection";
import HeroSection from "./components/heroSection";

const Home = () => {
  return (
    <HomeContainer>
      <HeroSection />
      {Data.collections.map((collection) => {
        return <Collection data={collection} />;
      })}
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div`
  overflow: hidden;
`;
