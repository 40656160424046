import React from "react";
import styled from "styled-components";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

import cookie1 from "../../../media/raw-products/cookie1.png";
import cookie2 from "../../../media/raw-products/cookie2.png";
import laddoo from "../../../media/raw-products/laddoo.png";
import scatter from "../../../media/raw-products/scatterCookies.png";
import girl from "../../../media/raw-products/girl.png";

const HeroSection = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  return (
    <SectionContainer>
      <motion.p className="title"></motion.p>
      <div className="cookie">
        <motion.img
          src={scatter}
          alt=""
          className="cookies"
          style={{
            scale: useTransform(scaleX, (value) => `${value * 13}`),
            x: useTransform(scaleX, (value) => `${-value * 1723}px`),
            y: useTransform(scaleX, (value) => `${-value * 1472}px`),
            opacity: useTransform(scaleX, (value) => `${-value * 200}px`),
            // rotate: useTransform(scaleX, (value) => `${value * 1645}deg`),
          }}
        />
        <motion.img
          src={scatter}
          alt=""
          className="cookies"
          style={{
            scale: useTransform(scaleX, (value) => `${value * 14}`),
            y: useTransform(scaleX, (value) => `${-value * 1400}px`),
            // x: useTransform(scaleX, (value) => `${value * 1400}px`),
            opacity: useTransform(scaleX, (value) => `${-value * 200}px`),
            // rotate: useTransform(scaleX, (value) => `${value * -1400}deg`),
          }}
        />
        <motion.img
          src={laddoo}
          alt=""
          className="cookies"
          style={{
            scale: useTransform(scaleX, (value) => `${value * 14}`),
            x: useTransform(scaleX, (value) => `${-value * 1400}px`),
            y: useTransform(scaleX, (value) => `${-value * 1400}px`),
            opacity: useTransform(scaleX, (value) => `${-value * 200}px`),
            rotate: useTransform(scaleX, (value) => `${value * 1400}deg`),
          }}
        />
        <motion.img
          src={scatter}
          alt=""
          className="cookies"
          style={{
            scale: useTransform(scaleX, (value) => `${value * 13}`),
            x: useTransform(scaleX, (value) => `${value * 1624}px`),
            y: useTransform(scaleX, (value) => `${-value * 1367}px`),
            opacity: useTransform(scaleX, (value) => `${-value * 200}px`),
            // rotate: useTransform(scaleX, (value) => `${value * 1432}deg`),
          }}
        />
        <motion.img
          src={cookie1}
          alt=""
          className="cookies"
          style={{
            y: useTransform(scaleX, (value) => `${-value * 1500}px`),
            scale: useTransform(scaleX, (value) => `${value * 10}`),
            opacity: useTransform(scaleX, (value) => `${-value * 200}px`),
            rotate: useTransform(scaleX, (value) => `${value * 1400}deg`),
          }}
        />

        <motion.img
          src={cookie2}
          alt=""
          className="cookies"
          style={{
            scale: useTransform(scaleX, (value) => `${value * 14}`),
            y: useTransform(scaleX, (value) => `${-value * 1400}px`),
            x: useTransform(scaleX, (value) => `${value * 1400}px`),
            opacity: useTransform(scaleX, (value) => `${-value * 200}px`),
            rotate: useTransform(scaleX, (value) => `${value * -1400}deg`),
          }}
        />

        <motion.img src={girl} alt="" className="girl" />
      </div>
    </SectionContainer>
  );
};

export default HeroSection;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: 0 auto;
  flex-direction: column;
  height: 60vh;
  background-color: #f55283;
  padding-top: 20px;
  overflow: hidden;
  z-index: 10;

  .cookie {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: transparent;
    margin-top: auto;
    z-index: 1500;
  }
  .girl {
    position: relative;
    margin: auto;
    width: 300px;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
  }
  .title {
    font-family: "Avenir";
    font-size: 50px;
    font-weight: 900;
    text-align: left;
    margin: auto 5vw;
    color: #fcf7f3;
    padding: 0 0;
  }
  .cookies {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 100px;
    height: 100px;
  }
  .mini {
    width: 20px;
    height: 20px;
    margin: 0;
  }
`;
